import {
  SET_BASKET_LOADING,
  SET_BASKET_SUCCESS,
  SET_BASKET_ITEM,
  SET_BASKET_ITEM_ERROR,
  SET_UNAUTHENTICATED_BASKET_ID
} from './../constants/basketConstants'

export default function reducer (state = {
  isLoadingBasket: false,
  isBasketSuccess: false,
  items: [],
  totalPrice: 0,
  discountPrice: 0,
  paginationInfo: null,
  currentItem: null,
  unauthenticatedBasketId: null
}, action) {
  switch (action.type) {
    case SET_BASKET_LOADING:
      return {
        ...state,
        isLoadingBasket: action.isLoadingBasket
      }

    case SET_BASKET_SUCCESS:
      return {
        ...state,
        basketId: action.basketId,
        isBasketSuccess: action.isBasketSuccess,
        items: action.items,
        totalPrice: action.totalPrice,
        discountPrice: action.discountPrice,
        paginationInfo: action.paginationInfo
      }

    case SET_BASKET_ITEM:
      return {
        ...state,
        currentItem: action.currentItem
      }

    case SET_BASKET_ITEM_ERROR:
      return {
        ...state,
        currentItem: false
      }

    case SET_UNAUTHENTICATED_BASKET_ID:
      return {
        ...state,
        unauthenticatedBasketId: action.basketId
      }

    default:
      return state
  }
}
