import { store } from './../store'

export const host = process.env.REACT_APP_API_HOST

export default class ServiceRoot {
  static getToken () {
    const state = store.getState()
    return state.reducers.login
  }

  static getItems (body) {
    return body['hydra:member']
  }

  static getPaginationInfo (body) {
    const view = body['hydra:view']

    if (view && view['hydra:first']) {
      return {
        current: parseInt(view['@id'].split('=').pop()),
        first: parseInt(view['hydra:first'].split('=').pop()),
        last: parseInt(view['hydra:last'].split('=').pop()),
        previous: view['hydra:previous'] && parseInt(view['hydra:previous'].split('=').pop()),
        next: view['hydra:next'] && parseInt(view['hydra:next'].split('=').pop())
      }
    }
  }
}
