import {
  SET_LOGIN_PENDING,
  SET_LOGIN_SUCCESS,
  SET_LOGIN_ERROR,
  CREATE_LOGIN_ALERT, REMOVE_LOGIN_ALERT
} from './../constants/loginConstants'

export default function reducer (state = {
  isLoginSuccess: false,
  isLoginPending: false,
  loginAlert: null,
  loginError: null,
  token: null
}, action) {
  switch (action.type) {
    case SET_LOGIN_PENDING:
      return {
        ...state,
        isLoginPending: action.isLoginPending,
        loginError: null,
        isLoginSuccess: false
      }

    case SET_LOGIN_SUCCESS:
      return {
        ...state,
        isLoginSuccess: action.isLoginSuccess,
        token: action.token,
        refreshToken: action.refreshToken,
        decodedToken: action.decodedToken
      }

    case SET_LOGIN_ERROR:
      return {
        ...state,
        loginError: action.loginError
      }
    case CREATE_LOGIN_ALERT:
      return {
        ...state,
        loginAlert: {
          status: action.status,
          text: action.text
        }
      }
    case REMOVE_LOGIN_ALERT:
      return {
        ...state,
        loginAlert: null
      }
    default:
      return state
  }
}
