import { CREATE_MODAL, REMOVE_MODAL, REMOVE_MODALS } from '../constants/modalConstants'

export default function reducer (state = [], action) {
  switch (action.type) {
    case CREATE_MODAL:
      return [...state, {
        title: action.title,
        content: action.content,
        actionComponent: action.actionComponent,
        itemId: action.itemId,
        frameless: action.frameless
      }]
    case REMOVE_MODAL: {
      let reducedModals = state
      // Remove the modal in question from the array
      reducedModals = reducedModals.filter(item => {
        return !(item.itemId === action.itemId)
      })
      return reducedModals
    }
    case REMOVE_MODALS:
      return []
    default:
      return state
  }
}
