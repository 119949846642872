import { CHANGE_LOCALE } from '../constants/localeConstants'

export default function reducer (state = 'en', action) {
  switch (action.type) {
    case CHANGE_LOCALE:
      return action.locale
    default:
      return state
  }
}
