import {
  SET_CAMPAIGN_LOADING,
  SET_CAMPAIGN, SET_CAMPAIGN_ERROR
} from '../constants/campaignConstants'

export default function reducer (state = {
  isLoading: false,
  campaign: null,
  error: false
}, action) {
  switch (action.type) {
    case SET_CAMPAIGN_LOADING:
      return {
        ...state,
        isLoading: action.isLoading
      }
    case SET_CAMPAIGN:
      return {
        ...state,
        campaign: action.campaign,
        error: false
      }
    case SET_CAMPAIGN_ERROR:
      return {
        ...state,
        campaign: null,
        error: true
      }
    default:
      return state
  }
}
