import { SET_LOADING } from '../constants/loadingConstants'

export default function reducer (state = false, action) {
  switch (action.type) {
    case SET_LOADING:
      return action.isLoading
    default:
      return state
  }
}
