import LoginService from '../services/LoginService'
import jwtDecode from 'jwt-decode'
import { store } from '../store'
import {
  SET_LOGIN_PENDING,
  SET_LOGIN_SUCCESS,
  SET_LOGIN_ERROR,
  LOGOUT,
  CREATE_LOGIN_ALERT,
  REMOVE_LOGIN_ALERT
} from './../constants/loginConstants'
import { createNotification } from './notificationActions'
import { setUserLoading } from './userActions'
import { fetchApiData } from '../Utils/ServiceUtils'
import { setUnauthenticatedBasketId } from './basketActions'

function setLoginPending (isLoginPending) {
  return {
    type: SET_LOGIN_PENDING,
    isLoginPending
  }
}

function setLoginSuccess (isLoginSuccess, token, refreshToken) {
  let decodedToken
  // Decode the login token
  if (token) {
    decodedToken = jwtDecode(token)
  }

  return {
    type: SET_LOGIN_SUCCESS,
    isLoginSuccess,
    token,
    refreshToken,
    decodedToken
  }
}

function setLoginError (loginError) {
  return {
    type: SET_LOGIN_ERROR,
    loginError
  }
}

function handleLoginResponse (response, dispatch, successCb = null) {
  if (response.status === 200) {
    response.json().then((body) => {
      dispatch(setUnauthenticatedBasketId(null))
      dispatch(setLoginSuccess(true, body.token, body.refresh_token))
      successCb && successCb(response.token)
    })
  } else {
    // Something's gone wrong, try again later
    dispatch(setLoginError(response.status))
  }
}

export function resetLogin () {
  return (dispatch) => {
    dispatch(setLoginError(null))
    dispatch(setLoginSuccess(false))
    dispatch(setLoginPending(false))
  }
}

export function login ({ ...values }) {
  return (dispatch) => {
    const basketId = store.getState().reducers.basket.unauthenticatedBasketId
    if (basketId) {
      values.basketId = basketId
    }

    dispatch(setLoginPending(true))
    dispatch(setLoginSuccess(false))
    dispatch(setLoginError(null))

    LoginService.login(values).then((response) => {
      dispatch(setLoginPending(false))

      handleLoginResponse(response, dispatch)
    })
  }
}

export function refreshExpiredLoginToken (successCb = null) {
  return (dispatch) => {
    if (LoginService.hasTokenExpired()) {
      LoginService.regenerateToken(LoginService.getToken().refreshToken).then((response) => {
        if (response.status !== 200) {
          dispatch(logoutWithoutNotification())
        }

        handleLoginResponse(response, dispatch, successCb)
      })
    } else {
      successCb && successCb()
    }
  }
}

export function logout () {
  return (dispatch) => {
    dispatch({ type: LOGOUT })

    dispatch(createNotification('success', 'You have been logged out'))
  }
}

export function logoutWithoutNotification () {
  return (dispatch) => {
    dispatch({ type: LOGOUT })
  }
}

export function resetPasswordRequest (email, successCallback) {
  return (dispatch) => {
    dispatch(setUserLoading(true))
    fetchApiData(dispatch, LoginService.resetPassword, email).then((response) => {
      dispatch(setUserLoading(false))
      if (response.status === 200) {
        response.json().then((body) => {
          dispatch(createLoginAlert('success', 'We have sent you an email with instructions on how to reset your password. If you do not receive an email, please check your Junk mailbox.'))
          successCallback()
        })
      } else {
        dispatch(createNotification('danger', 'Unable to request password reset'))
      }
    })
  }
}

export function createLoginAlert (status, text) {
  return (dispatch) => {
    dispatch({
      type: CREATE_LOGIN_ALERT,
      status,
      text
    })

    setTimeout(() => {
      dispatch({
        type: REMOVE_LOGIN_ALERT,
        status,
        text
      })
    }, 20000) // 20000ms = 20 seconds
  }
}
