import {
  CREATE_NOTIFICATION, NOTIFICATION_ATTACH_REMOVAL, REMOVE_ALL_NOTIFICATIONS,
  REMOVE_NOTIFICATION
} from '../constants/notificationConstants'

export default function reducer (state = [], action) {
  switch (action.type) {
    case CREATE_NOTIFICATION: {
      const newNotifications = [...state, {
        status: action.status,
        text: action.text,
        itemId: action.itemId,
        pendingRemoval: false
      }]

      return newNotifications
    }
    case REMOVE_NOTIFICATION: {
      let reducedNotifications = state

      // Remove the notification in question from the array
      reducedNotifications = reducedNotifications.filter(item => {
        return !(item.itemId === action.itemId)
      })

      // Return the new notification array
      return reducedNotifications
    }
    case NOTIFICATION_ATTACH_REMOVAL: {
      let notifications = state
      // Attach the pending removal flag to the required notification
      notifications = notifications.map(item => {
        if (item.itemId === action.itemId) {
          item.pendingRemoval = true
        }

        return item
      })
      // Return the new notification array
      return notifications
    }
    case REMOVE_ALL_NOTIFICATIONS:
      return []

    default:
      return state
  }
}
