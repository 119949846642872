import BasketService from '../services/BasketService'
import {
  SET_BASKET_LOADING,
  SET_BASKET_SUCCESS,
  SET_UNAUTHENTICATED_BASKET_ID
} from './../constants/basketConstants'
import { createNotification } from './notificationActions'
import { fetchApiData } from '../Utils/ServiceUtils'
import { store } from '../store'

function setBasketLoading (isLoadingBasket) {
  return {
    type: SET_BASKET_LOADING,
    isLoadingBasket
  }
}

export function setUnauthenticatedBasketId (basketId) {
  return {
    type: SET_UNAUTHENTICATED_BASKET_ID,
    basketId
  }
}

function setBasketSuccess (isBasketSuccess, basketId, items, totalPrice, discountPrice, paginationInfo) {
  return {
    type: SET_BASKET_SUCCESS,
    basketId,
    isBasketSuccess,
    items,
    totalPrice,
    discountPrice,
    paginationInfo
  }
}

function dispatchBasketResponse (dispatch, body) {
  dispatch(setBasketSuccess(
    true,
    BasketService.getBasketId(body),
    BasketService.getItems(body),
    BasketService.getTotalPrice(body),
    BasketService.getDiscountPrice(body),
    BasketService.getPaginationInfo(body)
  ))
}

export function getBasketItems (basketId, cb = null) {
  return (dispatch) => {
    if (basketId || store.getState().reducers.login.isLoginSuccess) {
      dispatch(setBasketLoading(true))

      fetchApiData(dispatch, BasketService.getBasket, basketId).then(response => {
        dispatch(setBasketLoading(false))
        if (response.status === 200) {
          response.json().then((body) => {
            dispatchBasketResponse(dispatch, body)
          })
        } else {
          // Something's gone wrong
          dispatch(createNotification('danger', 'Error loading basket quantities'))
        }

        cb && cb()
      })
    }
  }
}

async function createBasket (dispatch) {
  const response = await fetchApiData(dispatch, BasketService.createBasket)

  if (response.status === 201) {
    const body = await response.json()
    const basketId = body.id

    dispatch(setUnauthenticatedBasketId(body.id))
    return basketId
  } else {
    dispatch(createNotification('danger', 'Unable to add item'))
  }

  return null
}
export function addProductToBasket (basketId, productId, productVariantId, quantity, cb = null) {
  return async (dispatch) => {
    dispatch(setBasketLoading(true))

    let confirmedBasketId = basketId

    if (!basketId && !store.getState().reducers.login.isLoginSuccess) {
      confirmedBasketId = await createBasket(dispatch)
    }

    fetchApiData(dispatch, BasketService.addProduct, confirmedBasketId, productId, productVariantId, quantity).then((response) => {
      dispatch(setBasketLoading(false))
      if (response.status === 200) {
        response.json().then((body) => {
          dispatchBasketResponse(dispatch, body)
          cb && cb()
        })
      } else {
        dispatch(createNotification('danger', 'Unable to add item'))
      }
    })
  }
}

export function deleteProductFromBasket (basketId, productId, productVariantId, quantity, cb) {
  return (dispatch) => {
    dispatch(setBasketLoading(true))

    fetchApiData(dispatch, BasketService.removeProduct, basketId, productId, productVariantId, quantity).then((response) => {
      dispatch(setBasketLoading(false))

      if (response.status === 200) {
        // Fetch the updated basket items
        response.json().then((body) => {
          dispatchBasketResponse(dispatch, body)
          cb && cb()
        })
      } else {
        dispatch(createNotification('danger', 'Unable to remove item'))
      }
    })
  }
}

export function updateBasketProducts (basketId, products, cb = null) {
  return (dispatch) => {
    fetchApiData(dispatch, BasketService.updateProducts, basketId, products).then((response) => {
      if (response.status === 200) {
        cb && cb()
      } else {
        dispatch(createNotification('danger', 'Unable to create order'))
      }
    })
  }
}
