import { HIDE_COOKIE_BANNER } from '../constants/cookieConstants'

export default function reducer (state = {
  isBannerVisible: true
}, action) {
  switch (action.type) {
    case HIDE_COOKIE_BANNER:
      return {
        ...state,
        isBannerVisible: action.visibility
      }

    default:
      return state
  }
}
