import storage from 'redux-persist/lib/storage'

import { combineReducers } from 'redux'
import login from './loginReducers'
import signup from './signupReducers'
import products from './productReducers'
import basket from './basketReducers'
import payment from './paymentReducers'
import order from './orderReducers'
import user from './userReducers'
import notifications from './notificationReducers'
import cookie from './cookieReducers'
import delivery from './deliveryReducers'
import link from './linkReducers'
import modal from './modalReducers'
import subscription from './subscriptionReducers'
import address from './addressReducers'
import locale from './localeReducers'
import campaigns from './campaignReducers'
import shippingProfile from './shippingProfileReducers'
import isLoading from './loadingReducer'
import productCollections from './productCollectionReducers'

import { LOGOUT } from '../constants/loginConstants'

const reducers = combineReducers({
  login,
  signup,
  products,
  basket,
  payment,
  order,
  user,
  notifications,
  cookie,
  delivery,
  link,
  modal,
  subscription,
  address,
  locale,
  campaigns,
  shippingProfile,
  isLoading,
  productCollections
})

const rootReducer = (state, action) => {
  if (action.type === LOGOUT) {
    storage.removeItem('persist:root')

    state = {
      products: state.products,
      cookie: {
        isBannerVisible: state.cookie.isBannerVisible
      }
    }
  }

  return reducers(state, action)
}

export default rootReducer
