import { REMOVE_MODAL, CREATE_MODAL, REMOVE_MODALS } from '../constants/modalConstants'

function create (title, content, actionComponent, itemId = null, frameless = false) {
  itemId = itemId || Math.random().toString(36).substring(7)

  return {
    type: CREATE_MODAL,
    title,
    content,
    actionComponent,
    itemId,
    frameless
  }
}

export function hideModal (itemId) {
  return (dispatch) => {
    dispatch({ type: REMOVE_MODAL, itemId })
  }
}

export function hideAllModals () {
  return (dispatch) => {
    dispatch({ type: REMOVE_MODALS })
  }
}

export function createModal (title, content, actionComponent) {
  return (dispatch) => {
    dispatch(create(title, content, actionComponent, null))
  }
}

export function createFramelessModal (content) {
  return (dispatch) => {
    dispatch(create(null, content, null, null, true))
  }
}
