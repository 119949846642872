import React, { useEffect, useState } from 'react'
import Card from '../Card'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as modalActions from '../../../actions/modalActions'
import usePrevious from '../../../Utils/usePrevious'

export const Modal = ({ modals, ...props }) => {
  const [scrollPosition, setScrollPosition] = useState(0)
  const hide = (itemId) => {
    modals.length === 1 && window.scrollTo({
      top: scrollPosition,
      left: 0,
      behavior: 'smooth'
    })
    props.hideModal(itemId)
  }

  useEffect(() => {
    props.hideAllModals()
  }, [])

  const previousModals = usePrevious(modals)

  useEffect(() => {
    if (previousModals <= modals && modals.length === 1) {
      // This is the first modal
      setScrollPosition(window.scrollY)
    }

    if (modals.length > 0) {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth'
      })
    }
  }, [modals])

  if (modals.length !== 0) {
    return (
      <div className='modal-container' onClick={() => hide(modals[modals.length - 1].itemId)}>
        {modals.map((modal, index) => (
          <div key={`modal_${index}`} className={`modal modal-depth-${modals.length - index}`} onClick={e => e.stopPropagation()}>
            <button className='btn btn-icon-round' onClick={() => hide(modal.itemId)}><i className='fas fa-times' /></button>

            <Card className={`card ${!modal.frameless && 'card-padded'} no-margin`}>
              {modal.title && <h1>{modal.title}</h1>}
              {!modal.content || typeof modal.content === 'string' ? <p>{modal.content}</p> : modal.content(() => hide(modal.itemId))}
              <div className='row modal-btn-actions'>
                <div className='col-12'>
                  {typeof modal.actionComponent === 'function' && modal.actionComponent(() => hide(modal.itemId))}
                </div>
              </div>
            </Card>
          </div>
        ))}
      </div>
    )
  }

  return null
}

const mapStateToProps = (state) => {
  return {
    modals: state.reducers.modal
  }
}

Modal.propTypes = {
  modals: PropTypes.array,
  hideModal: PropTypes.func,
  hideAllModals: PropTypes.func
}

export default connect(
  mapStateToProps,
  (dispatch) => bindActionCreators(modalActions, dispatch)
)(Modal)
