import { SET_AVAILABLE_SHIPPING_PROFILES } from '../constants/shippingProfileConstants'

export default function reducer (state = {
  availableProfiles: null
}, action) {
  switch (action.type) {
    case SET_AVAILABLE_SHIPPING_PROFILES:
      return {
        ...state,
        availableProfiles: action.profiles
      }
    default:
      return state
  }
}
