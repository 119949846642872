import React from 'react'
import { Navigate } from 'react-router-dom'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as loginActions from './../../../actions/loginActions'
import PropTypes from 'prop-types'

export class AuthenticatedRoute extends React.Component {
  render () {
    const { role, children } = this.props

    if (this.props.token && this.props.decodedToken) {
      if (!role || this.props.decodedToken.roles.includes(role)) {
        return children
      } else {
        return <Navigate to={{ pathname: '/', state: { from: this.props.location } }} replace />
      }
    }

    return <Navigate to={{ pathname: '/user/login', state: { from: this.props.location } }} replace />
  }
}

/* istanbul ignore next */
const mapStateToProps = (state) => {
  return {
    isLoginSuccess: state.reducers.login.isLoginSuccess,
    token: state.reducers.login.token,
    decodedToken: state.reducers.login.decodedToken
  }
}

AuthenticatedRoute.propTypes = {
  component: PropTypes.any,
  token: PropTypes.string,
  location: PropTypes.object,
  decodedToken: PropTypes.object,
  role: PropTypes.string,
  refreshExpiredLoginToken: PropTypes.func
}

/* istanbul ignore next */
export default connect(
  mapStateToProps,
  (dispatch) => bindActionCreators(loginActions, dispatch)
)(AuthenticatedRoute)
