import ServiceRoot, { host } from './ServiceRoot'
import { getDecodedToken } from '../Utils/ServiceUtils'

class LoginService extends ServiceRoot {
  static signup (values) {
    return fetch(`${host}/user/signup`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(values)
    })
  }

  static login (values) {
    return fetch(`${host}/user/login`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(values)
    })
  }

  static hasTokenExpired () {
    const decodedToken = getDecodedToken()
    if (decodedToken) {
      const currentTime = (new Date()).getTime() / 1000
      const expiryTime = decodedToken.exp

      // Has the token expired
      if (expiryTime < currentTime) {
        return true
      }
    }

    return false
  }

  static regenerateToken (refreshToken) {
    return fetch(`${host}/token/refresh`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        refresh_token: refreshToken
      })
    })
  }

  static resetPassword (email) {
    return fetch(`${host}/user/forgot-password`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        username: email
      })
    })
  }
}

export default LoginService
