import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as notificationActions from '../../../actions/notificationActions'
import PropTypes from 'prop-types'
import Notification from './Notification'

const NotificationAlert = (props) => {
  useEffect(() => {
    props.removeAllNotifications()
  }, [])

  return (
    <div className='alert-notifications'>
      {props.notifications.length > 0 && props.notifications.map((item, key) => (
        <Notification key={key} status={item.status} text={item.text} pendingRemoval={item.pendingRemoval} />
      ))}
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    notifications: state.reducers.notifications
  }
}

NotificationAlert.propTypes = {
  notifications: PropTypes.array,
  removeAllNotifications: PropTypes.func
}

export default connect(
  mapStateToProps,
  (dispatch) => bindActionCreators(notificationActions, dispatch)
)(NotificationAlert)
