import {
  CREATE_NOTIFICATION,
  REMOVE_NOTIFICATION,
  REMOVE_ALL_NOTIFICATIONS,
  NOTIFICATION_ATTACH_REMOVAL
} from '../constants/notificationConstants'

function create (status, text, itemId) {
  return {
    type: CREATE_NOTIFICATION,
    status,
    text,
    itemId
  }
}

function remove (itemId) {
  return {
    type: REMOVE_NOTIFICATION,
    itemId
  }
}

function attachRemoval (itemId) {
  return {
    type: NOTIFICATION_ATTACH_REMOVAL,
    itemId
  }
}

function removeAll () {
  return {
    type: REMOVE_ALL_NOTIFICATIONS
  }
}

export function createNotification (status, text) {
  return (dispatch) => {
    const id = Math.random().toString(36)

    dispatch(create(status, text, id))

    setTimeout(() => {
      dispatch(attachRemoval(id))
    }, 7500)

    setTimeout(() => {
      dispatch(remove(id))
    }, 8000)
  }
}

export function removeAllNotifications () {
  return (dispatch) => {
    dispatch(removeAll())
  }
}
