import ServiceRoot, { host } from './ServiceRoot'
import { getBearerTokenValue } from '../Utils/ServiceUtils'

export default class UserService extends ServiceRoot {
  static getItems (body) {
    return body['hydra:member']
  }

  static getUser () {
    return fetch(`${host}/user`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: getBearerTokenValue()
      }
    })
  }

  static getUsers () {
    return fetch(`${host}/users`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: getBearerTokenValue()
      }
    })
  }

  static updateEmail (values) {
    return fetch(`${host}/user`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/merge-patch+json',
        Authorization: getBearerTokenValue()
      },
      body: JSON.stringify({
        username: values.email,
        currentPassword: values.currentPassword
      })
    })
  }

  static updateAddress (address) {
    return fetch(`${host}/user`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/merge-patch+json',
        Authorization: getBearerTokenValue()
      },
      body: JSON.stringify({
        address
      })
    })
  }

  static updatePassword (password, currentPassword) {
    return fetch(`${host}/user`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/merge-patch+json',
        Authorization: getBearerTokenValue()
      },
      body: JSON.stringify({
        password,
        currentPassword
      })
    })
  }

  static resetPassword (values) {
    return fetch(`${host}/user/password`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(values)
    })
  }

  static deleteUser (currentPassword) {
    return fetch(`${host}/user`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: getBearerTokenValue()
      },
      body: JSON.stringify({
        currentPassword
      })
    })
  }
}
