import {
  SET_PRODUCTS_LOADING,
  SET_PRODUCTS_SUCCESS,
  SET_PRODUCTS_ERROR,
  SET_PRODUCT_ITEM,
  SET_PRODUCT_ITEM_ERROR,
  UPDATE_PRODUCT_ITEM,
  SET_PRODUCT_ADDITION_ERROR,
  CLEAR_PRODUCT_ITEM, SET_PRODUCT_ITEM_LOADING
} from './../constants/productConstants'

export default function reducer (state = {
  isLoadingProducts: false,
  isProductsSucess: false,
  productsError: null,
  items: null,
  paginationInfo: null,
  // TODO: Move the 'current item' state into a sub-object
  currentItem: null,
  currentItemError: null,
  currentItemLoading: false,
  productCaches: {}
}, action) {
  switch (action.type) {
    case SET_PRODUCTS_LOADING:
      return {
        ...state,
        isLoadingProducts: action.isLoadingProducts
      }

    case SET_PRODUCTS_SUCCESS:
      if (action.cacheKey) {
        return {
          ...state,
          productCaches: {
            ...state.productCaches,
            [action.cacheKey]: {
              items: action.items,
              paginationInfo: action.paginationInfo,
              isSuccess: action.isProductsSuccess
            }
          }
        }
      } else {
        return {
          ...state,
          isProductsSuccess: action.isProductsSuccess,
          items: action.items,
          paginationInfo: action.paginationInfo
        }
      }

    case SET_PRODUCTS_ERROR:
      return {
        ...state,
        productsError: action.productsError
      }

    case SET_PRODUCT_ITEM:
      return {
        ...state,
        currentItem: action.currentItem
      }

    case SET_PRODUCT_ITEM_ERROR:
      return {
        ...state,
        currentItemError: action.itemError,
        currentItem: false
      }
    case SET_PRODUCT_ADDITION_ERROR:
      return {
        ...state,
        additionError: true
      }
    case UPDATE_PRODUCT_ITEM: {
      // Rebuild the item array and update item we want
      const items = state.items.map(item => {
        if (item.id === action.item.id) {
          return action.item
        }
        return item
      })

      return {
        ...state,
        items
      }
    }
    case CLEAR_PRODUCT_ITEM:
      return {
        ...state,
        currentItem: null,
        currentItemError: null
      }
    case SET_PRODUCT_ITEM_LOADING:
      return {
        ...state,
        currentItemLoading: action.isLoading
      }
    default:
      return state
  }
}
