import { logoutWithoutNotification, refreshExpiredLoginToken } from '../actions/loginActions'
import { store } from '../store'

export const fetchApiData = async (dispatch, serviceMethod, ...serviceArgs) => {
  return new Promise((resolve) => {
    dispatch(refreshExpiredLoginToken(() => {
      serviceMethod(...serviceArgs).then(response => {
        if (response.status === 401) {
          // The token has not expired, but is invalid for some reason
          dispatch(logoutWithoutNotification())
        } else {
          resolve(response)
        }
      })
    }))
  })
}

export const getToken = () => {
  const state = store.getState()
  return state.reducers.login.token
}

export const getBearerTokenValue = () => {
  const token = getToken()
  return token ? `Bearer ${getToken()}` : undefined
}

export const getDecodedToken = () => {
  const state = store.getState()
  return state.reducers.login.decodedToken
}
