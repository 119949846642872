import React from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as cookieActions from '../../../actions/cookieActions'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

const CookieBanner = (props) => {
  const { t } = useTranslation()

  const handleHide = () => {
    props.setCookieBannerVisibility(false)
  }

  if (!props.isBannerVisible) {
    return null
  }

  return (
    <div className='banner banner-primary'>
      <div className='banner-content'>
        {t('copy:cookieBanner.title')}
        <br />
        <small>
          {t('copy:cookieBanner.description')} <Link to='/terms/privacy-policy' className='btn btn-link-inline text-primary'>{t('cta:cookieDetails')}</Link>
        </small>
      </div>

      <button className='btn btn-success btn-icon-round banner-close' onClick={handleHide}>
        <i className='fas fa-times' />
      </button>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    isBannerVisible: state.reducers.cookie.isBannerVisible
  }
}

CookieBanner.propTypes = {
  isBannerVisible: PropTypes.bool,
  setCookieBannerVisibility: PropTypes.func,
  t: PropTypes.func
}

export default connect(
  mapStateToProps,
  (dispatch) => bindActionCreators(cookieActions, dispatch)
)(CookieBanner)
