/* istanbul ignore file */

import { useEffect } from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

const TranslationGate = (props) => {
  useEffect(() => {
    props.i18n.changeLanguage(props.locale)
  }, [props.locale])

  return props.children
}

TranslationGate.propTypes = {
  locale: PropTypes.string,
  i18n: PropTypes.object
}

const mapStateToProps = (state) => {
  return {
    locale: state.reducers.locale
  }
}

export default connect(
  mapStateToProps
)(withTranslation()(TranslationGate))
