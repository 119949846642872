import React from 'react'
import Card from '../Card'
import PropTypes from 'prop-types'
import logo from '../../../images/logo.svg'

const LoginCard = (props) => {
  return (
    <div id='login-card'>
      <div className='login-logo'><img src={logo} /></div>
      <Card className='card card-padded' isLoading={props.isLoading}>
        <h1>{props.title}</h1>
        {props.children}
      </Card>
    </div>
  )
}

LoginCard.propTypes = {
  title: PropTypes.string,
  children: PropTypes.any,
  isLoading: PropTypes.bool
}

export default LoginCard
