import {
  SET_ORDER,
  SET_ORDERS,
  SET_ORDER_LOADING,
  SET_ORDERS_LOADING,
  SET_DELIVERY_DETAILS,
  SET_ORDER_CHECKOUT_TYPE
} from './../constants/orderConstants'

export default function reducer (state = {
  isLoadingOrder: false,
  deliveryDetails: null,
  currentItem: null,
  checkoutType: null,
  paginationInfo: null
}, action) {
  switch (action.type) {
    case SET_ORDER:
      return {
        ...state,
        currentItem: action.order
      }
    case SET_ORDER_LOADING:
      return {
        ...state,
        isLoadingOrder: action.isLoadingOrder
      }
    case SET_ORDERS_LOADING:
      return {
        ...state,
        isLoadingOrders: action.isLoadingOrders
      }
    case SET_ORDERS:
      return {
        ...state,
        orders: action.orders,
        paginationInfo: action.paginationInfo
      }
    case SET_DELIVERY_DETAILS:
      return {
        ...state,
        deliveryDetails: action.details
      }
    case SET_ORDER_CHECKOUT_TYPE:
      return {
        ...state,
        checkoutType: action.checkoutType
      }
    default:
      return state
  }
}
