import { SET_PRODUCT_COLLECTIONS } from '../constants/productCollectionConstants'

export default function reducer (state = {
  collections: []
}, action) {
  switch (action.type) {
    case SET_PRODUCT_COLLECTIONS:
      return {
        ...state,
        collections: action.collections
      }
    default:
      return state
  }
}
