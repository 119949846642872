import {
  SET_DELIVERIES_LOADING,
  SET_DELIVERIES,
  SET_SHIPMENT_TYPES,
  SET_SHIPMENT_TYPES_LOADING,
  SET_PREFERRED_SHIPMENT_DETAILS,
  CLEAR_PREFERRED_SHIPMENT_DETAILS
} from '../constants/deliveryConstants'

export default function reducer (state = {
  deliveries: null,
  isLoadingDeliveries: false,
  isLoadingTypes: false,
  types: null
}, action) {
  switch (action.type) {
    case SET_DELIVERIES_LOADING:
      return {
        ...state,
        isLoadingDeliveries: action.isLoadingDeliveries
      }
    case SET_DELIVERIES:
      return {
        ...state,
        deliveries: action.deliveries
      }
    case SET_SHIPMENT_TYPES:
      return {
        ...state,
        types: action.types
      }
    case SET_SHIPMENT_TYPES_LOADING:
      return {
        ...state,
        isLoadingTypes: action.isLoading
      }
    case SET_PREFERRED_SHIPMENT_DETAILS:
      return {
        ...state,
        preferredShipmentDetails: action.preferredShipmentDetails
      }
    case CLEAR_PREFERRED_SHIPMENT_DETAILS:
      return {
        ...state,
        preferredShipmentDetails: null
      }
    default:
      return state
  }
}
