/* istanbul ignore file */
import React, { Suspense } from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { store, persistor } from './../store'
import AuthenticatedRoute from './Utils/AuthenticatedRoute'
import NotificationAlert from './Components/NotificationAlert'
import ScrollToTop from './Utils/ScrollToTop'
import CookieBanner from './Components/CookieBanner'
import Modal from './Components/Modal'
import TranslationGate from './Utils/TranslationGate'
import '../i18n'
import LoginCard from './Components/LoginCard'

const Home = React.lazy(() => import('./Pages/Home'))
const Signup = React.lazy(() => import('./Pages/Signup'))
const Login = React.lazy(() => import('./Pages/Login'))
const Products = React.lazy(() => import('./Pages/Products'))
const Product = React.lazy(() => import('./Pages/Product'))
const User = React.lazy(() => import('./Pages/User'))
const ShoppingBasket = React.lazy(() => import('./Pages/ShoppingBasket'))
const OrderHistory = React.lazy(() => import('./Pages/User/OrderHistory'))
const PrivacyPolicy = React.lazy(() => import('./Pages/Terms/PrivacyPolicy'))
const ForgotPassword = React.lazy(() => import('./Pages/ForgotPassword'))
const Settings = React.lazy(() => import('./Pages/User/Settings'))
const PaymentOptions = React.lazy(() => import('./Pages/User/PaymentOptions'))
const OneTimeLink = React.lazy(() => import('./Pages/OneTimeLink'))
const OrderDetails = React.lazy(() => import('./Pages/User/OrderDetails'))
const Addresses = React.lazy(() => import('./Pages/User/Addresses'))
const Checkout = React.lazy(() => import('./Pages/Checkout'))
const NotFound = React.lazy(() => import('./Pages/Error/NotFound'))

export class App extends React.Component {
  render () {
    return (
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <TranslationGate>
            <NotificationAlert />
            <Modal />
            <BrowserRouter>
              <>
                <CookieBanner />

                <ScrollToTop>
                  <Suspense fallback={<LoginCard isLoading />}>
                    <Routes>
                      <Route exact path='/user/signup' element={<Signup />} />
                      <Route exact path='/user/login' element={<Login />} />
                      <Route exact path='/user/forgot-password' element={<ForgotPassword />} />

                      <Route exact path='/link/:type/:token' element={<OneTimeLink />} />

                      <Route exact path='/terms/privacy-policy' element={<PrivacyPolicy />} />

                      <Route exact path='/basket' element={<ShoppingBasket />} />

                      <Route exact path='/me' element={<AuthenticatedRoute><User /></AuthenticatedRoute>} />
                      <Route exact path='/me/account' element={<AuthenticatedRoute><Settings /></AuthenticatedRoute>} />
                      <Route exact path='/me/payment' element={<AuthenticatedRoute><PaymentOptions /></AuthenticatedRoute>} />
                      <Route exact path='/me/addresses' element={<AuthenticatedRoute><Addresses /></AuthenticatedRoute>} />
                      <Route exact path='/me/history' element={<AuthenticatedRoute><OrderHistory /></AuthenticatedRoute>} />
                      <Route exact path='/me/history/:id' element={<AuthenticatedRoute><OrderDetails /></AuthenticatedRoute>} />

                      <Route exact path='/checkout' element={<AuthenticatedRoute><Checkout /></AuthenticatedRoute>} />

                      <Route path='/product/:id' element={<Product />} />

                      <Route exact path='/category/:category' element={<Products />} />
                      <Route exact path='/products' element={<Products />} />

                      <Route exact path='/' element={<Home />} />

                      <Route element={<NotFound />} />
                    </Routes>
                  </Suspense>
                </ScrollToTop>
              </>
            </BrowserRouter>
          </TranslationGate>
        </PersistGate>
      </Provider>
    )
  }
}

console.log('App version: ', process.env.REACT_APP_APP_VERSION)

const root = ReactDOM.createRoot(document.getElementById('app'))
root.render(<App />)
