import { SET_ADDRESS, SET_ADDRESSES, SET_ADDRESSES_LOADING } from '../constants/addressConstants'

export default function reducer (state = {
  addresses: [],
  isLoading: false,
  paginationInfo: null
}, action) {
  switch (action.type) {
    case SET_ADDRESSES:
      return {
        ...state,
        addresses: action.addresses
      }
    case SET_ADDRESS: {
      // Rebuild the item array and update item we want
      const addresses = state.addresses.map(address => {
        if (address.id === action.address.id) {
          return action.address
        }
        return address
      })

      return {
        ...state,
        addresses
      }
    }
    case SET_ADDRESSES_LOADING:
      return {
        ...state,
        isLoading: action.isLoading
      }
    default:
      return state
  }
}
