/* istanbul ignore file */

import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

import en from './locale/en'

const resources = {
  en
}

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: 'en',

    defaultNS: 'copy',

    keySeparator: '.', // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false
    }
  })

export default i18n
