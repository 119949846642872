import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as loginActions from '../../actions/loginActions'

export function ScrollToTop ({ history, children, refreshExpiredLoginToken }) {
  const location = useLocation()

  useEffect(() => {
    refreshExpiredLoginToken()
    window.scrollTo(0, 0)
  }, [location])

  return <>{children}</>
}

ScrollToTop.propTypes = {
  history: PropTypes.object,
  children: PropTypes.object,
  refreshExpiredLoginToken: PropTypes.func
}

export default connect(
  null,
  (dispatch) => bindActionCreators(loginActions, dispatch)
)(ScrollToTop)
