import UserService from '../services/UserService'
import { SET_USER_LOADING, SET_USER, SET_USERS } from '../constants/userConstants'
import { createNotification } from './notificationActions'
import { createLoginAlert, logout, logoutWithoutNotification } from './loginActions'
import { fetchApiData } from '../Utils/ServiceUtils'

export function setUserLoading (isLoading) {
  return {
    type: SET_USER_LOADING,
    isLoading
  }
}

function setUser (user) {
  return {
    type: SET_USER,
    user
  }
}

function setUsers (users, paginationInfo) {
  return {
    type: SET_USERS,
    users,
    paginationInfo
  }
}

function handleUserResponse (response, dispatch) {
  if (response.status === 200) {
    response.json().then((body) => {
      dispatch(setUser(body))
    })
  } else {
    // Something's gone wrong, try again later
    dispatch(setUser(null))
  }
}

export function getUser (values) {
  return (dispatch) => {
    dispatch(setUserLoading(true))

    fetchApiData(dispatch, UserService.getUser, values).then((response) => {
      dispatch(setUserLoading(false))

      handleUserResponse(response, dispatch)
    })
  }
}

export function updateEmail (values, successCb) {
  return (dispatch) => {
    dispatch(setUserLoading(true))
    fetchApiData(dispatch, UserService.updateEmail, values).then((response) => {
      dispatch(setUserLoading(false))

      if (response.status === 200) {
        dispatch(logout())
        dispatch(createLoginAlert('success', 'We have sent you an email to confirm your new email address. Please confirm your new email before logging back into your account.'))
        successCb && successCb()
      } else if (response.status === 403) {
        dispatch(createNotification('danger', 'Email address already in use'))
      } else if (response.status === 401) {
        dispatch(createNotification('danger', 'The password you\'ve entered is incorrect'))
      } else {
        dispatch(createNotification('danger', 'Unable to update email'))
      }
    })
  }
}

export function updateAddress (address, successCallback = null) {
  return (dispatch) => {
    dispatch(setUserLoading(true))
    fetchApiData(dispatch, UserService.updateAddress, address).then((response) => {
      dispatch(setUserLoading(false))

      if (response.status === 200) {
        successCallback && successCallback()
      }

      handleUserResponse(response, dispatch)
    })
  }
}

export function resetChangePassword (password, token, successCallback) {
  return (dispatch) => {
    dispatch(setUserLoading(true))
    fetchApiData(dispatch, UserService.resetPassword, { password, token }).then((response) => {
      dispatch(setUserLoading(false))
      if (response.status === 200) {
        dispatch(logoutWithoutNotification())
        dispatch(createLoginAlert('success', 'Your password has been reset, you can now sign into your account using your new password.'))
        successCallback && successCallback()
      } else {
        dispatch(createNotification('danger', 'Unable to change password'))
      }
    })
  }
}

export function updatePassword (password, currentPassword, successCallback) {
  return (dispatch) => {
    dispatch(setUserLoading(true))
    UserService.updatePassword(password, currentPassword).then((response) => {
      dispatch(setUserLoading(false))
      if (response.status === 200) {
        dispatch(logout())
        dispatch(createLoginAlert('success', 'Your password has been changed, please sign into your account using your new password.'))
        successCallback()
      } else if (response.status === 401) {
        dispatch(createNotification('danger', 'Your current password is incorrect'))
      } else {
        dispatch(createNotification('danger', 'Unable to change password'))
      }
    })
  }
}

export function getUsers () {
  return (dispatch) => {
    fetchApiData(dispatch, UserService.getUsers).then((response) => {
      if (response.status === 200) {
        response.json().then((body) => {
          dispatch(setUsers(
            UserService.getItems(body),
            UserService.getPaginationInfo(body)
          ))
        })
      }
    })
  }
}

export function deleteUser (currentPassword, cb = null) {
  return (dispatch) => {
    dispatch(setUserLoading(true))

    UserService.deleteUser(currentPassword).then((response) => {
      dispatch(setUserLoading(false))

      if (response.status === 204) {
        dispatch(logout())
        dispatch(createLoginAlert('danger', 'Your account has been deleted.'))
      } else if (response.status === 401) {
        dispatch(createNotification('danger', 'Password incorrect'))
      } else {
        dispatch(createNotification('danger', 'Unable to delete your account'))
      }

      cb && cb()
    })
  }
}
