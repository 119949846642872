import { SET_SUBSCRIPTION, SET_SUBSCRIPTION_LOADING, SET_ORDER_FREQUENCIES } from '../constants/subscriptionConstants'

export default function reducer (state = {
  isLoading: false,
  subscription: null,
  orderFrequencies: null
}, action) {
  switch (action.type) {
    case SET_SUBSCRIPTION_LOADING:
      return {
        ...state,
        isLoading: action.isLoading
      }
    case SET_SUBSCRIPTION:
      return {
        ...state,
        subscription: action.subscription
      }
    case SET_ORDER_FREQUENCIES:
      return {
        ...state,
        orderFrequencies: action.orderFrequencies
      }
    default:
      return state
  }
}
