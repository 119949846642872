import {
  SET_CLIENT_TOKEN,
  SET_CLIENT_TOKEN_ERROR,
  SET_CURRENT_PAYMENT_METHOD,
  SET_PAYMENT_METHODS,
  SET_PAYMENT_TOKEN_LOADING,
  SET_PAYMENTS_LOADING
} from './../constants/paymentConstants'

export default function reducer (state = {
  clientTokenError: null,
  clientToken: null,
  isLoading: false
}, action) {
  switch (action.type) {
    case SET_CLIENT_TOKEN:
      return {
        ...state,
        clientToken: action.token
      }

    case SET_CLIENT_TOKEN_ERROR:
      return {
        ...state,
        clientTokenError: action.error
      }

    case SET_PAYMENT_TOKEN_LOADING:
      return {
        ...state,
        isTokenLoading: action.isTokenLoading
      }
    case SET_CURRENT_PAYMENT_METHOD:
      return {
        ...state,
        selectedMethod: action.paymentMethod
      }
    case SET_PAYMENT_METHODS:
      return {
        ...state,
        paymentMethods: action.paymentMethods
      }
    case SET_PAYMENTS_LOADING:
      return {
        ...state,
        isLoading: action.isLoading
      }
    default:
      return state
  }
}
