import React from 'react'
import PropTypes from 'prop-types'

class Card extends React.Component {
  render () {
    const { isLoading, ...props } = this.props

    return (
      <div {...props}>
        {props.children}
        {isLoading
          ? (
            <div className='card-loading-facade'>
              <i className='fas fa-spinner' />
            </div>
            )
          : null}
      </div>
    )
  }
}

Card.propTypes = {
  isLoading: PropTypes.bool
}

export default Card
