import { VERIFY_LINK, VERIFY_LINK_LOADING } from '../constants/linkConstants'

export default function reducer (state = {}, action) {
  switch (action.type) {
    case VERIFY_LINK:
      return {
        ...state,
        link: action.link
      }
    case VERIFY_LINK_LOADING:
      return {
        ...state,
        isLoading: action.isLoading
      }
    default:
      return state
  }
}
