import {
  SET_USER_LOADING,
  SET_USER, SET_USERS
} from './../constants/userConstants'

export default function reducer (state = {
  isLoading: false,
  user: null,
  users: null,
  paginationInfo: null
}, action) {
  switch (action.type) {
    case SET_USER_LOADING:
      return {
        ...state,
        isLoading: action.isLoading
      }

    case SET_USER:
      return {
        ...state,
        user: action.user
      }

    case SET_USERS:
      return {
        ...state,
        users: action.users,
        paginationInfo: action.paginationInfo
      }

    default:
      return state
  }
}
