import ServiceRoot, { host } from './ServiceRoot'
import { getBearerTokenValue } from '../Utils/ServiceUtils'

class ProductService extends ServiceRoot {
  static getBasket (basketId) {
    const uri = `${host}/basket${basketId ? `/${basketId}` : ''}`
    const headers = {
      'Content-Type': 'application/merge-patch+json'
    }
    if (!basketId) {
      headers.Authorization = getBearerTokenValue()
    }

    return fetch(uri, {
      method: 'GET',
      headers
    })
  }

  static createBasket () {
    return fetch(`${host}/basket`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: getBearerTokenValue()
      },
      body: JSON.stringify({})
    })
  }

  static addProduct (basketId, productId, productVariantId, quantity = 1) {
    const productBody = {
      product: productId,
      quantity
    }

    if (productVariantId) {
      productBody.productVariant = productVariantId
    }

    const uri = `${host}/basket${basketId ? `/${basketId}` : ''}`
    const headers = {
      'Content-Type': 'application/merge-patch+json'
    }
    if (!basketId) {
      headers.Authorization = getBearerTokenValue()
    }

    return fetch(uri, {
      method: 'PATCH',
      headers,
      body: JSON.stringify([
        productBody
      ])
    })
  }

  static removeProduct (basketId, productId, productVariantId, quantity = 1) {
    const productBody = {
      product: productId,
      // Negative quantity to decrement product quantity or remove product (if only one)
      quantity: -quantity
    }

    if (productVariantId) {
      productBody.productVariant = productVariantId
    }

    const uri = `${host}/basket${basketId ? `/${basketId}` : ''}`
    const headers = {
      'Content-Type': 'application/merge-patch+json'
    }
    if (!basketId) {
      headers.Authorization = getBearerTokenValue()
    }

    return fetch(uri, {
      method: 'PATCH',
      headers,
      body: JSON.stringify([
        productBody
      ])
    })
  }

  static updateProducts (basketId, products) {
    const uri = `${host}/basket${basketId ? `/${basketId}` : ''}`

    const headers = {
      'Content-Type': 'application/merge-patch+json'
    }
    if (!basketId) {
      headers.Authorization = getBearerTokenValue()
    }

    return fetch(uri, {
      method: 'PATCH',
      headers,
      body: JSON.stringify(products)
    })
  }

  static getBasketId (body) {
    return body.id
  }

  static getItems (body) {
    return body.products
  }

  static getTotalPrice (body) {
    return body.totalPrice
  }

  static getDiscountPrice (body) {
    return body.discountPrice
  }

  static getPaginationInfo (body) {
    const view = body['hydra:view']

    if (view) {
      return {
        current: parseInt(view['@id'].split('=').pop()),
        first: parseInt(view['hydra:first'].split('=').pop()),
        last: parseInt(view['hydra:last'].split('=').pop()),
        previous: view['hydra:previous'] && parseInt(view['hydra:previous'].split('=').pop()),
        next: view['hydra:next'] && parseInt(view['hydra:next'].split('=').pop())
      }
    } else {
      return null
    }
  }
}

export default ProductService
