import React from 'react'
import PropTypes from 'prop-types'

const Notification = ({ status, text, pendingRemoval }) => {
  return (
    <div className={`alert alert-${status} alert-notification ${!pendingRemoval && 'alert-open'}`}>
      {text}
    </div>
  )
}

Notification.propTypes = {
  status: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  pendingRemoval: PropTypes.bool.isRequired
}

export default Notification
