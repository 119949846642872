import { HIDE_COOKIE_BANNER } from '../constants/cookieConstants'

export function setCookieBannerVisibility (visibility) {
  return (dispatch) => {
    dispatch({
      type: HIDE_COOKIE_BANNER,
      visibility
    })
  }
}
