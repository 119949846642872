import { createStore, applyMiddleware, compose, combineReducers } from 'redux'
import thunkMiddleware from 'redux-thunk'
import { routerReducer } from 'react-router-redux'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import reducers from './../reducers'
import logger from 'redux-logger'

const persistConfig = {
  key: 'root',
  storage
}

const rootReducer = combineReducers({
  routing: routerReducer,
  reducers
})

const middleware = [
  thunkMiddleware
]

/* istanbul ignore next */
if (process.env.NODE_ENV === 'development') {
  middleware.push(logger)
}

const enhancer = compose(
  applyMiddleware(...middleware)
)

const persistedReducer = persistReducer(persistConfig, rootReducer)

export const store = createStore(persistedReducer, {}, enhancer)
export const persistor = persistStore(store)
